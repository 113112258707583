<script setup>
import {onMounted, ref, watch} from "vue";
import SearchBlock from "@/Components/Shared/Restaurant/SearchBlock.vue";
import Card from "@/Components/Shared/Cards/Card.vue";
import {TransitionRoot} from "@headlessui/vue";
import debounce from "lodash/debounce";

const props = defineProps({
    categories: Array,
    modelValue: String,
    currentCategory: String,
});

defineEmits(['update:modelValue']);

const showSearch = ref(false);

const searchBar = ref();
const isStickied = ref(false);

let observer = null;

onMounted(() => {
    observer = new IntersectionObserver(([e]) => {
        isStickied.value = ! e.isIntersecting;
    }, {
        threshold: 1.0
    });

    observer.observe(searchBar.value.$el)
});

const categoryBar = ref();
const showShadow = ref(false);
const categoryElements = {};

onMounted(() => watch(() => props.currentCategory, debounce(cat => {
    if (! cat) {
        return;
    }

    categoryBar.value.scrollLeft = categoryElements[cat].offsetLeft - categoryElements[cat].offsetWidth / 2 - 10;
}, 150)));

onMounted(() => categoryBar.value.addEventListener('scroll', event => showShadow.value = event.target.scrollLeft > 0));
</script>

<template>
    <Card class="isolate sticky top-[-1px] divide-y z-10" :class="{ 'rounded-t-none': isStickied }" ref="searchBar">
        <div class="flex items-center divide-x">
            <button class="bg-gray-100 hover:bg-gray-300 p-3 m-2 rounded-3xl cursor-pointer" @click.prevent="showSearch = !showSearch">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-4 w-4">
                    <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                </svg>
            </button>

            <div class="flex divide-x overflow-x-auto scroll-smooth transition-shadow duration-150" :class="{ 'shadow-[inset_5px_0_5px_-5px_rgba(0,0,0,0.1),inset_-5px_0_5px_-5px_rgba(0,0,0,0.1)]': showShadow }" ref="categoryBar">
                <template v-for="category in categories" :key="category.id">
                    <a
                        :href="`#${category.slug}`"
                        class="flex-none hover:bg-orange-100 p-4"
                        :class="{ 'bg-orange-100': currentCategory === category.id }"
                        :ref="(el) => categoryElements[category.id] = el"
                        :dusk="`menu-nav-${category.id}`"
                        v-if="category.products.length > 0">{{ category.name }}</a>
                </template>
            </div>
        </div>

        <TransitionRoot
            :show="showSearch"
            enter="transform transition duration-250"
            enter-from="-translate-y-6 opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="transform transition duration-250"
            leave-from="translate-y-0 opacity-100"
            leave-to="-translate-y-6 opacity-0">
            <SearchBlock :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
        </TransitionRoot>
    </Card>
</template>
