<script setup>
import TextInput from "@/Components/Shared/Forms/TextInput.vue";
import {computed} from "vue";

const props = defineProps({
    modelValue: Number,
    variant: {
        type: String,
        default: () => 'medium'
    },
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        if (value < 1) {
            value = 1;
        }

        if (value > 99) {
            value = 99;
        }

        emit('update:modelValue', value);
    }
});

const size = computed(() => ({
    small: {
        buttons: 'w-8 h-8',
        icon: 'w-[12px] h-[12px]',
        field: 'h-8 w-16',
    },
    medium: {
        buttons: 'w-10 h-10',
        icon: 'w-[16px] h-[16px]',
        field: 'w-32',
    },
}[props.variant]));
</script>

<template>
    <div class="inline-flex flex-row justify-between">
        <button :class="size.buttons" class="flex justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue-500 px-2 py-1 aspect-square rounded-l-lg my-auto" @click="$emit('update:modelValue', modelValue - 1)" dusk="decrement">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" :class="size.icon" class="fill-blue-500">
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
            </svg>
        </button>

        <TextInput id="quantity" type="number" step="1" min="1" max="99" :class="size.field" :value="value" @input="$emit('update:modelValue', +$event.target.value)" required dusk="quantity" />

        <button :class="size.buttons" class="flex justify-center items-center bg-blue-200 hover:bg-blue-300 text-blue-500 px-2 py-1 aspect-square rounded-r-lg my-auto" @click="$emit('update:modelValue', modelValue + 1)" dusk="increment">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" :class="size.icon" class="fill-blue-500"> <!-- plus -->
                <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
            </svg>
        </button>
    </div>
</template>
