<script setup>
import Dialog from '@/Components/Shared/Dialogs/Dialog.vue';
import dayjs from "dayjs";
import {computed, ref} from 'vue';

const weekdays = {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
};

const props = defineProps({
    title: String,
    isOpen: Boolean,
    times: Array,
    exceptions: Array,
    dusk: String,
});

const date = dayjs().format('YYYY-MM-DD');
const isDialogOpen = ref(false);

const setIsOpen = (value) => {
    isDialogOpen.value = value;
};

const today = computed(() => props.times.find(time => time.is_today));
const todayException = computed(() => props.exceptions.find(exception => !exception.is_closed && exception.day.form === date));
</script>

<template>
    <button
        :class="{
            'bg-green-500 text-white hover:bg-green-600 active:bg-green-600 disabled:bg-green-300': isOpen,
            'bg-red-500 text-white hover:bg-red-600 active:bg-red-600 disabled:bg-red-300': !isOpen
        }"
        class="flex flex-col 2xl:flex-row w-full justify-between items-center cursor-pointer rounded-xl transition ease-in-out duration-150 text-sm xl:text-base px-2 py-2 xl:px-4 xl:py-4 shadow-md"
        @click="setIsOpen(true)" :dusk="`${dusk}-button`"
    >
        <span class="font-medium">{{ title }}</span>

        <span>
            <template v-if="todayException">{{ todayException.opens_at?.formatted }} - {{ todayException.closes_at?.formatted }}</template>
            <template v-else-if="!today.is_closed">{{ today.opens_at?.formatted }} - {{ today.closes_at?.formatted }}</template>
            <template v-else-if="today.is_closed">Gesloten</template>
        </span>
    </button>

    <Dialog :title="title" :is-open="isDialogOpen" :set-is-open="setIsOpen" class="rounded-xl" :dusk="`${dusk}-modal`">
        <p class="font-bold text-gray-700">Reguliere dagen</p>
        <table class="w-full" :class="{ 'mb-8': exceptions.length > 0 }">
            <tbody>
            <tr v-for="time in times" :key="time.id" :dusk="`time-${time.id}`">
                <td class="py-1 pr-1" :class="{ 'font-bold text-indigo-500': time.is_today, 'font-medium': ! time.is_today }">
                    {{ weekdays[time.weekday] }}
                </td>
                <td class="text-right p-1" v-if="!time.is_closed" :class="{ 'font-bold text-indigo-500': time.is_today }">
                    {{ time.opens_at?.formatted }} - {{ time.closes_at?.formatted }}
                </td>
                <td class="text-right py-1 pl-1" v-if="time.is_closed" :class="{ 'font-bold text-indigo-500': time.is_today }">
                    Gesloten
                </td>
            </tr>
            </tbody>
        </table>

        <template v-if="exceptions.length > 0">
            <p class="font-bold text-gray-700">Speciale dagen</p>
            <table class="w-full mb-2">
                <tbody>
                <tr v-for="exception in exceptions" :key="exception.id" :dusk="`exception-${exception.id}`" :class="{ 'font-bold text-indigo-500': exception.is_today }">
                    <td class="py-1 pr-1" :class="{ 'font-medium': !exception.is_today }">
                        {{ exception.day.formatted }} <br>
                        <div class="text-sm">{{ exception.description }}</div>
                    </td>
                    <td class="text-right py-1 pl-1">
                        <template v-if="! exception.is_closed">
                            {{ exception.opens_at?.formatted }} - {{ exception.closes_at?.formatted }}
                        </template>
                        <template v-else>
                            <span :class="{ 'text-red-500': !exception.is_today }">Gesloten</span>
                        </template>
                    </td>
                </tr>
                </tbody>
            </table>
        </template>
    </Dialog>
</template>
