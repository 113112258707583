<script setup>
import {onMounted, ref} from "vue";
import Dialog from "@/Components/Shared/Dialogs/Dialog.vue";
import {Link, usePage} from "@inertiajs/vue3";
import route from '@/Helpers/route';

const props = defineProps({
    show: {
        type: Boolean,
        default: () => false,
    },
    canDeliver: Boolean,
    canPickup: Boolean,
    acceptsReservations: Boolean,
});

const page = usePage();

const showDialog = ref(false);
onMounted(() => showDialog.value = props.show);

const isLoadingDelivery = ref(false);
const isLoadingPickup = ref(false);

const closeDialog = () => {
    isLoadingDelivery.value = false;
    isLoadingPickup.value = false;

    setTimeout(() => showDialog.value = false, 100);
};
</script>

<template>
    <div>
        <Dialog title="Bezorgen of afhalen?" :is-open="showDialog" dusk="transportation-modal">

            <div class="flex flex-col lg:flex-row justify-between gap-4">
                <Link :href="route(`${page.props.routing_group}.cart.transportation`, { [page.props.routing_key]: page.props.routing_target })" method="post" as="button" type="button" :data="{ transportation: 'delivery' }"
                      class="flex w-full h-16 justify-center items-center cursor-pointer rounded-xl transition ease-in-out duration-150 bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-600 disabled:bg-blue-300 font-medium tracking-wide"
                      dusk="delivery" v-if="canDeliver" :onStart="() => isLoadingDelivery = true" :onFinish="closeDialog" preserve-scroll>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-white w-[26px] h-[26px] animate-spin mr-4" v-if="isLoadingDelivery">
                        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fill-white w-[26px] h-[26px] mr-4" v-else> <!-- motorcycle -->
                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
                    </svg>

                    Bezorgen
                </Link>

                <Link :href="route(`${page.props.routing_group}.cart.transportation`, { [page.props.routing_key]: page.props.routing_target })" method="post" as="button" type="button" :data="{ transportation: 'pickup' }"
                      class="flex w-full h-16 justify-center items-center cursor-pointer rounded-xl transition ease-in-out duration-150 bg-indigo-500 text-white hover:bg-indigo-600 active:bg-indigo-600 disabled:bg-indigo-300 font-medium tracking-wide"
                      dusk="pickup" v-if="canPickup" :onStart="() => isLoadingPickup = true" :onFinish="closeDialog" preserve-scroll>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-white w-[26px] h-[26px] animate-spin mr-4" v-if="isLoadingPickup">
                        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"/>
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="fill-white w-[26px] h-[26px] mr-4" v-else> <!-- location-dot -->
                        <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
                    </svg>

                    Afhalen
                </Link>
            </div>

            <Link :href="route(`${page.props.routing_group}.reservations.create`, { [page.props.routing_key]: page.props.routing_target })"
                  class="flex w-full h-16 justify-center items-center cursor-pointer rounded-xl transition ease-in-out duration-150 bg-orange-500 text-white hover:bg-orange-600 active:bg-orange-600 disabled:bg-orange-300 font-medium tracking-wide mt-4"
                  dusk="reservation" v-if="acceptsReservations" preserve-scroll>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fill-white w-[26px] h-[26px] mr-4"> <!-- shop -->
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path d="M36.8 192H603.2c20.3 0 36.8-16.5 36.8-36.8c0-7.3-2.2-14.4-6.2-20.4L558.2 21.4C549.3 8 534.4 0 518.3 0H121.7c-16 0-31 8-39.9 21.4L6.2 134.7c-4 6.1-6.2 13.2-6.2 20.4C0 175.5 16.5 192 36.8 192zM64 224V384v80c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V384 224H320V384H128V224H64zm448 0V480c0 17.7 14.3 32 32 32s32-14.3 32-32V224H512z"/>
                </svg>

                Direct reserveren
            </Link>

        </Dialog>
    </div>
</template>
