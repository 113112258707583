<script setup>
import {price} from "@/Helpers/formatters";
import InputLabel from "@/Components/Shared/Forms/InputLabel.vue";
import {computed} from "vue";
import Radio from "@/Components/Shared/Forms/Radio.vue";

const props = defineProps({
    modelValue: Array,
    group: Object,
});

const emit = defineEmits(['update:modelValue']);

const proxy = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emit('update:modelValue', val);
    },
});
</script>

<template>
    <div class="block">
        <div class="flex items-center" v-for="option in group.options" :key="option.id" :dusk="`group-${group.id}`">
            <Radio class="radio" :id="`option-${option.id}`" :name="`option[${group.id}]`" :value="option.id" v-model:checked="proxy" :dusk="`option-${option.id}`">
                <InputLabel :for="`option-${option.id}`">
                    {{ option.name }}
                    <span v-if="option.price_incl_vat !== 0">({{ price(option.price_incl_vat) }})</span>
                </InputLabel>
            </Radio>
        </div>
    </div>
</template>
