<script setup>
import CategoryHeader from "@/Components/Shared/Restaurant/CategoryHeader.vue";
import ProductBlock from "@/Components/Shared/Restaurant/ProductBlock.vue";
import {TransitionRoot} from '@headlessui/vue';
import {onMounted, ref} from "vue";

const props = defineProps({
    category: Object,
    products: Array,
    optionGroups: Array,
    transportation: String,
    lazyLoad: Boolean,
});

const emit = defineEmits(['visible', 'invisible']);

const cat = ref();
const isShowing = ref(false);
const isInViewport = ref(false);

let observer = null;

const toggle = () => {
    isShowing.value = !isShowing.value;
};

onMounted(() => isShowing.value = props.category.is_open_by_default);

onMounted(() => {
    addEventListener('hashchange', () => {
        if (location.hash === `#${props.category.slug}` && !isShowing.value) {
            toggle();
        }
    });
});

onMounted(() => {
    observer = new IntersectionObserver(([e]) => {
        isInViewport.value = e.isIntersecting;

        if (e.isIntersecting) {
            emit('visible', props.category.id);
        } else {
            emit('invisible', props.category.id);
        }
    }, {
        threshold: 0.3
    });

    observer.observe(cat.value);
});
</script>

<template>
    <div class="isolate mb-8 scroll-mt-20" :id="`${category.slug}`" :dusk="`category-${category.id}`" ref="cat">
        <CategoryHeader
            :category="category"
            :is-showing="isShowing"
            :toggle="toggle"
            :lazy-load="lazyLoad"
            class="relative z-10 mb-4"
        />

        <TransitionRoot
            :show="isShowing"
            enter="transform transition duration-250"
            enter-from="-translate-y-48 opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="transform transition duration-250"
            leave-from="translate-y-0 opacity-100"
            leave-to="-translate-y-48 opacity-0"
        >
            <div
                class="grid grid-cols-1 gap-2"
                :class="{
                    'grid-cols-1': category.columns === 1,
                    'grid-cols-1 lg:grid-cols-2': category.columns === 2,
                    'grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3': category.columns === 3
                }"
            >
                <ProductBlock
                    v-for="product in products"
                    :key="product.id"
                    :option-groups="optionGroups.filter(group => product.option_groups.map(group => group.id).includes(group.id))"
                    :product="product"
                    :transportation="transportation"
                    :layout="category.layout"
                    :lazy-load="lazyLoad"
                    class="relative z-0"
                />
            </div>
        </TransitionRoot>
    </div>
</template>
